import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Prism from "prismjs";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import ArticleAuthor from '~2-components/ArticleAuthor/ArticleAuthor';
import { articleAuthorData } from '~/data/articleAuthorData';
import PageSection from '~2-components/PageSection/PageSection';
import GatsbyImage from 'gatsby-image';
import formatPhoneNumber from '~utils/formatPhoneNumber';

const articleAuthorEg = `import ArticleAuthor from '~2-components/ArticleAuthor/ArticleAuthor';

<ArticleAuthor
    imgUrl='' // Required
    imgAlt='' // Required
    cmsImage= React.node // Required if coming from CMS. Usually a Gatsby Image
    authorName='' // Required
    authorPosition='' // Optional
    authorPhone='' // Required
    authorEmail='' // Required
    btnUrl='' // Required
    sidebar=boolean // Optional (sidebar version only shows on desktop)
/>
`;

const SgArticleAuthor = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    const allAuthorTiles = useStaticQuery(
        graphql`
            query SGAllAuthorTiles {
                allKontentItemProfile {
                    nodes {
                        elements {
                            first_name {
                                value
                            }
                            last_name {
                                value
                            }
                            position {
                                value
                            }
                            phone {
                                value
                            }
                            email {
                                value
                            }
                            thumbnail_image {
                                value {
                                    description
                                    name
                                    fluid(maxWidth: 584) {
                                        ...KontentAssetFluid_withWebp
                                    }
                                }
                            }
                            page_url {
                                value
                            }
                        }
                    }
                }
            }
    `);

    const allAuthors = allAuthorTiles.allKontentItemProfile.nodes;

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Article Author</h1>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={articleAuthorEg}
                    />

                    <h3>Example</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <ArticleAuthor
                        {...articleAuthorData}
                        authorPosition="Lawyer"
                    />
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h2>Sidebar author example</h2>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <PageSection
                        textLayout="two_columns"
                        rightColContent={<ArticleAuthor
                            {...articleAuthorData}
                            sidebar={true}
                        />}
                    >
                        <p>ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu turpis egestas pretium aenean. Faucibus ornare suspendisse sed nisi lacus sed viverra tellus in. Arcu non sodales neque sodales ut. Ut aliquam purus sit amet luctus venenatis lectus. At auctor urna nunc id cursus metus aliquam. Fusce ut placerat orci nulla pellentesque dignissim enim sit amet. Blandit turpis cursus in hac habitasse platea dictumst quisque sagittis. Scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus. Rhoncus dolor purus non enim. Tellus pellentesque eu tincidunt tortor aliquam nulla. Congue eu consequat ac felis. Magna sit amet purus gravida quis. Blandit turpis cursus in hac habitasse platea dictumst quisque.</p>
                        <p>Feugiat pretium nibh ipsum consequat nisl vel pretium lectus. Vehicula ipsum a arcu cursus. Sit amet volutpat consequat mauris nunc congue nisi. Ullamcorper malesuada proin libero nunc consequat interdum varius. Varius vel pharetra vel turpis nunc. Platea dictumst quisque sagittis purus sit amet volutpat consequat mauris. Imperdiet nulla malesuada pellentesque elit eget gravida cum. Eu nisl nunc mi ipsum faucibus. Dolor magna eget est lorem ipsum dolor. Cras sed felis eget velit aliquet sagittis id. Diam sit amet nisl suscipit adipiscing bibendum est ultricies integer. Sit amet facilisis magna etiam tempor orci eu lobortis. Dictum varius duis at consectetur lorem. Libero justo laoreet sit amet cursus sit amet. Neque vitae tempus quam pellentesque nec nam. Eget nunc scelerisque viverra mauris in. Ullamcorper eget nulla facilisi etiam dignissim diam. Integer vitae justo eget magna fermentum iaculis eu non. Fusce ut placerat orci nulla pellentesque dignissim enim. Enim neque volutpat ac tincidunt.</p>
                        <p>At risus viverra adipiscing at in tellus. Adipiscing bibendum est ultricies integer quis auctor elit sed vulputate. Dui sapien eget mi proin sed libero. Sit amet risus nullam eget. Congue mauris rhoncus aenean vel elit scelerisque mauris. Purus ut faucibus pulvinar elementum integer enim neque volutpat ac. Nullam vehicula ipsum a arcu cursus vitae congue mauris. At ultrices mi tempus imperdiet nulla malesuada pellentesque elit. Cursus mattis molestie a iaculis at erat pellentesque adipiscing commodo. Ut tortor pretium viverra suspendisse potenti nullam ac tortor. Luctus venenatis lectus magna fringilla urna porttitor rhoncus dolor. Aliquet sagittis id consectetur purus ut faucibus.</p>
                    </PageSection>
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h2>All LIVE Kontent author tiles</h2>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    {allAuthors.map((item, i) => {
                        const author = item.elements;
                        const image = author.thumbnail_image.value[0] ? author.thumbnail_image.value[0] : null;
                        const cmsImage = image && <GatsbyImage
                            key={image.name}
                            alt={image.description}
                            fluid={image.fluid}
                            style={{width: '100%'}}
                        />;


                        return (
                            <ArticleAuthor
                                key={i}
                                cmsImage={cmsImage}
                                authorName={`${author.first_name.value} ${author.last_name.value}`}
                                authorPosition={author.position.value}
                                formattedPhone={formatPhoneNumber(author.phone.value)}
                                authorPhone={author.phone.value}
                                authorEmail={author.email.value}
                                btnUrl={author.page_url.value}
                            />
                        );
                    })}
                </div>
            </section>
        </SgLayout>
    );
};

export default SgArticleAuthor;
