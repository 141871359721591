import React from 'react';
import PropTypes from 'prop-types';
import Button from '~1-core/buttons/Button';
import Icon from '~2-components/IconInline/IconInline';
import IconIllustration from '~2-components/IconIllustration/IconIllustration';
import requiredIf from '~utils/requiredIf';

const ArticleAuthor = (props) => {
    const {
        imgUrl,
        imgAlt,
        cmsImage,
        authorName,
        authorPosition,
        formattedPhone,
        authorPhone,
        authorEmail,
        btnUrl,
        sidebar,
        genericAuthor
    } = props;

    return (
        <div className={`${sidebar ? "sidebar-author" : "article-author"}`}>
            <div className={`author-image ${genericAuthor ? " svg-image" : ""}`}>
                {/* Handles Gatsby Images */}
                {(!genericAuthor && cmsImage) &&
                    cmsImage
                }

                {imgUrl &&
                    <img src={imgUrl} alt={imgAlt} />
                }

                {genericAuthor &&
                    <IconIllustration icon="redlichs-logo" />
                }
            </div>
            <div className="author-details">
                <h4 className="author-name">{authorName}</h4>
                {!genericAuthor &&
                <>
                    { authorPosition &&
                    <div className="author-position">
                        <p>{authorPosition}</p>
                    </div>
                    }
                    <div className="author-phone">
                        <Icon icon={`phone`} />
                        <a href={`tel:${formattedPhone}`}>{authorPhone}</a>
                    </div>
                    <div className="author-email">
                        <Icon icon={`envelope`} />
                        <a href={`mailto:${authorEmail}`}>{authorEmail}</a>
                    </div>
                </>}
            </div>
            <Button
                href={genericAuthor ? 'contact-us' : btnUrl}
                skin={`2`}
            >{genericAuthor ? 'Contact us' : 'View profile'}</Button>
        </div>
    );
};

ArticleAuthor.propTypes = {
    imgUrl: requiredIf(PropTypes.string, props => !props.cmsImage),
    imgAlt: requiredIf(PropTypes.string, props => props.imgUrl),
    cmsImage: PropTypes.node,
    authorName: PropTypes.string.isRequired,
    authorPosition: PropTypes.string,
    formattedPhone: PropTypes.string.isRequired,
    authorPhone: PropTypes.string.isRequired,
    authorEmail: PropTypes.string.isRequired,
    btnUrl: PropTypes.string.isRequired,
    sidebar: PropTypes.bool,
    genericAuthor: PropTypes.bool
};

export default ArticleAuthor;
